import { createI18n } from "vue-i18n/dist/vue-i18n";

const defaultLang = "en_US";

let messages = {}; //可选主题
const files = require.context(".", false, /\.js$/); //第一个参数表示相对的文件目录，第二个参数表示是否包括子目录中的文件，第三个参数表示引入的文件匹配的正则表达式。
files.keys().forEach((key) => {
  if (key === "./index.js") return;
  let optionKey = key.substring(
    key.lastIndexOf("/") + 1,
    key.lastIndexOf(".js")
  ); //截取文件名称
  messages[`${optionKey}`] = require(`${key}`);
});

const i18n = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale: defaultLang, // set locale
  fallbackLocale: "zh_CN", // set fallback locale
  messages, // set locale messages
});

/*------------------------------------
*            设置语言配置
对外提供一个setup方法，用来修改我们的语言；
------------------------------------*/
export const langSetup = (langkey) => {
  if (!langkey) {
    return;
  }
  let langName = langkey.replace(/\s+/g, ""); //去除所有空格

  // 如果设置没有在可选语言里，则不可修改语言
  if (!messages[langName]) {
    console.log("没有在可选语言里，不可修改语言");
    return;
  }
  document.body.className = `lang-${langName}`;
  document.body.setAttribute("lang", langName); //给body设置lang属性
  window.localStorage.setItem("LANG", langName); //缓存语言
  // 修改全局变量
  i18n.global.locale.value = langName;
};

/**
 * 语言初始化
 * @function initLang
 * 从本地存储取，设置语言
 */
export const initLang = function () {
  let localLang = window.localStorage.getItem("LANG");
  if (localLang) {
    langSetup(localLang); //设置本地缓存语言
  } else {
    langSetup(defaultLang); //设置默认语言
  }
};

//把i18n绑定到window上，方便在vue组件以外的文件使用语言包
window.i18n = i18n;
window.initLang = initLang;
window.langSetup = langSetup;

export { i18n };
