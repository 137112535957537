<template>
  <router-view></router-view>
</template>

<script setup>
import config from "@/style/variables.module.scss";
import { onMounted, onUnmounted } from "vue";

// 获取滚动条宽度
function getScrollbarWidth() {
  let noScroll,
    scroll,
    oDiv = document.createElement("DIV");
  oDiv.style.cssText =
    "position:absolute;top:-1000px;width:100px;height:100px; overflow:hidden;";
  noScroll = document.body.appendChild(oDiv).clientWidth;
  oDiv.style.overflowY = "scroll";
  scroll = oDiv.clientWidth;
  document.body.removeChild(oDiv);
  return noScroll - scroll;
}
const resizeWidth = () => {
  let initFontSize = 16;
  let bodyWidth = window.innerWidth;
  bodyWidth = bodyWidth - getScrollbarWidth();
  let baseWidth = bodyWidth > config.mdWidth ? 1920 : bodyWidth;
  // let baseWidth = 1920;
  let newFontSize = initFontSize * (bodyWidth / baseWidth);
  document.documentElement.style.fontSize = newFontSize + "px";
};
resizeWidth();
onMounted(() => {
  window.addEventListener("resize", resizeWidth);
});
onUnmounted(() => {
  window.removeEventListener("resize", resizeWidth);
});
</script>
<style lang="scss">
body {
  margin: 0;
  padding: 0;
  #app {
    position: relative;
    overflow: hidden;
  }
}
p {
  margin: 0;
}
</style>
