import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/style/font.css";
import "@/style/common.css";
import "@/style/index.scss";
import { i18n } from "@/lang";
window.initLang(); //直接初始化语言

createApp(App).use(store).use(router).use(i18n).mount("#app");
