import { createRouter, createWebHistory } from "vue-router";
// import { menuRoute } from "./router";
// const menu = menuRoute
//   .map((item) => {
//     const { name, aliasName, component, path, children = [] } = item;
//     return [
//       {
//         name,
//         aliasName,
//         component,
//         path,
//       },
//       ...children,
//     ];
//   })
//   .flat(2);
const routes = [
  {
    path: "/",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ "@/views/Test.vue"),
  },
  // {
  //   path: "/",
  //   redirect: "/home",
  //   name: "Layout",
  //   component: () => import("@/components/Layout/index"),
  //   children: [
  //     ...menu,
  //     {
  //       path: "/news-details",
  //       name: "NewsDetails",
  //       component: () => import("@/views/NewsAndViews/Details"),
  //     },
  //   ],
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
