const nav = {
  home: "HOME",
  whoWeAre: "WHO WE ARE",
  whatWeDo: "WHAT WE DO",
  esg: "ESG",
  newsroom: "NEWSROOM",
};
const footer = {
  touch: "GET IN TOUCH",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  submit: "SUBMIT",
  section1: [
    "Shanghai, China",
    "Vienna, Austria",
    "General inquiries: mail{'@'}evomics.net",
    "HR inquiries: hr_helpdesk{'@'}evomics.net",
  ],
  section1Length: () => footer.section1.length + "",
  section2T: "ABOUT EVOMICS",
  section2: ["Who we are", "What we do", "ESG", "Newsroom"],
  section3T: "FIND US",
  copyRight: "Copyright © 2023 Evomics All Rights Reserved.",
};

const home = {
  carouselList: [
    {
      title: "WELCOME TO EVOMICS",
      desc: "A Leading Nuclear Medicine Company",
    },
    {
      title: "EVOMICS MEDICAL",
      desc: "Beyond The Visible",
    },
    {
      title: "STAY TUNED",
      desc: "Our new website is launching soon…",
    },
  ],
  section1: {
    title: "WHO WE ARE",
    desc: "Founded in 2018, we are a world-class nuclear medicine company committed to unleashing the power of radiation to make innovative radiotherapeutics accessible & available to patients around the world.",
    p: "With deep footprints in Europe, China and North America, we are dedicating to bring fully integrated diagnostics, radiotherapeutics and artificial intelligence solutions for improvements of patient outcome.",
  },
  section2: {
    title: "WHAT WE DO",
    p1: "Starting with clinical transformation, we have constructed a full-process platform to efficiently transform nuclear medicine, with image analysis at its core",
    p2: "Evomics Medical has opened up the entire process of radiopharmaceutical research and development centered on our three main technology platforms, and has deployed radiopharmaceutical pipelines through introduction, our proprietary research, and cooperative development.",
    p3: "Evomics Medical’s three core platforms are:",
    list: [
      "Our medical technology transformation platform comprises: Mechanism exploration, drug target screening, and clinical transformation",
      "Evomics Imaging AI research and development platform: standardization of clinical data, assistance in data analysis, and patient screening for clinical plans",
      "Evomics Nuclear drug design platform: Increasing the residence time and radioisotope exposure in tumor lesions",
    ],
    listLength: () => home.section2.list.length + "",
  },
  visions: [
    "OUR VISION",
    "Beyond the visible",
    "Radiation is a double-edged sword. When brandished with ill-intentions or negligence, it is a menacing specter inflicting death and severe injury. Yet when wielded as an instrument of good, this elemental force reveals otherwise undiscoverable maladies and injuries, and succors those stricken by life-threatening disease.",
    "At Evomics Medical, we have devoted ourselves to and embarked on our mission to channel the power of radiation as a beneficent instrument, one bestowing healing and wellness on those afflicted by grievous illness, operating in tandem with the awesome might of artificial intelligence. Evomics stands at the confluence of these two potent streams and in the front rank of human advancement and progress in quest of our ideal of good health for all.",
  ],
  visionsLength: () => home.visions.length + "",
  values: [
    "OUR VALUES",
    "Harnessing the power of radiation to do good",
    "Evomics is unswervingly convinced that good health should be a public commons and deem it the inalienable right of all humankind. We commit all our energies and resources to devising superlative, innovative applications of radiation and artificial intelligence in our imaging and drug delivery platforms in furtherance of this ethos, and to breaking down the barriers to affordable quality healthcare for all.",
  ],
  valuesLength: () => home.values.length + "",
};
const whoWeAre = {
  title: "EVOMICS MEDICAL",
  desc: "Harnessing the power of radiation to do good",
  subMenuList: ["OUR COMPANY", "OUR TEAM", "SAB", "INVESTOR"],
  subMenuListLength: () => whoWeAre.subMenuList.length + "",
  ourCompany: {
    aboutUs: "ABOUT US",
    aboutUsT:
      "Founded in 2018, Evomics Medical is a leading global nuclear medicine transformation platform. ",

    aboutUsP1:
      " Our company collaborates with top partners in the industry to build a full-process nuclear medicine system that spans the full range from target molecular screening to post-market production and supply, all centered around nuclear medicine.",
    aboutUsP2:
      " The firm's three core platforms ease many pain points in radiopharmaceutical research and development, effectively curtail the drug research and development cycle, and enhance the R&D success rate.",
  },
  visions: {
    vision: "OUR VISION",
    visionDesc: "Beyond the visible",

    visionP1:
      "Radiation is a double-edged sword. When brandished with ill-intentions or negligence, it is a menacing specter inflicting death and severe injury. Yet when wielded as an instrument of good, this elemental force reveals otherwise undiscoverable maladies and injuries, and succors those stricken by life-threatening disease.",
    visionP2:
      "At Evomics Medical, we have devoted ourselves to and embarked on our mission to channel the power of radiation as a beneficent instrument, one bestowing healing and wellness on those afflicted by grievous illness, operating in tandem with the awesome might of artificial intelligence. Evomics stands at the confluence of these two potent streams and in the front rank of human advancement and progress in quest of our ideal of good health for all.",
  },
  values: {
    values: "OUR VALUES",
    valuesDesc: "Harnessing the power of radiation to do good",
    valuesP:
      "Evomics is unswervingly convinced that good health should be a public commons and deem it the inalienable right of all humankind. We commit all our energies and resources to devising superlative, innovative applications of radiation and artificial intelligence in our imaging and drug delivery platforms in furtherance of this ethos, and to breaking down the barriers to affordable quality healthcare for all.",
  },
  teams: [
    {
      name: "Dr Wang Shiwei",
      keywords: ["Founder", "Chairman ", "Chief Executive Officer"],
      descList: [
        "Successfully incubated/invested/co-founded innovative pharmaceutical companies such as Xinxu Biotech, Zaiji Pharmaceuticals, Yingli Pharmaceuticals, Anlixirong and other innovative pharmaceutical companies in China and the United States, and served as directors and executives",
        "Incubated companies cover radiopharmaceuticals, targeted small molecule drugs, monoclonal antibody drugs, ADC drugs, peptide drugs, cell therapy and other fields Dr Wang holds a PhD in Biochemistry and Molecular Biology from Peking University",
        "",
      ],
    },
    {
      name: "Dr Marcus Hacker",
      keywords: ["Co-founder", "Chief Medical Officer"],
      descList: [
        "One of the world's most influential nuclear medicine experts",
        "Leading researcher in the field of nuclear medicine worldwide",
        "President of the Austrian Society of Nuclear Medicine, President of the Cardiovascular Branch of the European Society of Nuclear Medicine, Vice-President of the German Society of Nuclear Medicine, Distinguished Expert of the International Atomic Energy Agency",
        "Director of the Department of Medical Imaging at Vienna General Hospital, Professor of Nuclear Medicine at the Medical University of Vienna",
      ],
    },
    {
      name: "Wang Shifeng MBA",
      keywords: ["Co-Founder", "Director", "Chief Operating Officer"],
      descList: [
        "Before co-founding Evomics Medical, he served as a senior reporter at the Industrial Economy Center of China Business News, focusing on reporting on high-end manufacturing and technology. Many of his important reports have caused an extensive social impact",
        "Founded and created the world's leading innovative medical technology community platform The Yuan as the editor-in-chief",
        "Served as a media public relations consultant for multinational technology companies such as Rockwell Automation and ABB",
      ],
    },
    {
      name: "Dr Xiang Li",
      keywords: ["Co-Founder", "Chief Science Officer"],
      descList: [
        "One of the most influential nuclear medicine scholars in all of Europe. Research results published in Circulation, JACC, JNM, EJNMMI and other top journals",
        "Won the SNMMI Young Investigator Award of the American Society of Nuclear Medicine many times",
        "Professor of Nuclear Medicine, Vienna General Hospital/Medical University of Vienna, Austria",
        "Adjunct professor of Department of Nuclear Medicine, Beijing Anzhen Hospital, and Shanxi Medical University",
      ],
    },
  ].map(({ name, keywords, descList }) => {
    return {
      name,
      keywords,
      keywordsLength: keywords.length + "",
      descList,
      descListLength: descList.length + "",
    };
  }),
};
const whatWeDo = {
  title: "DEDICATED TO NUCLEAR",
  desc: "Evomics Medical develops targeted radiopharmaceuticals<br/>and AI imaging products to create closed-loop integrated nuclear medicine diagnoses and treatments",
  subMenu: ["OUR APPROACH", "OUR SCIENCE", "OUR PIPELINE"],
  subMenuListLength: () => whatWeDo.subMenu.length + "",
  approach:
    "Evomics Medical has opened up the entire process of drug research and development, centered on our nuclear medicine technology transformation platform, AI imaging platform, and nuclear medicine design platform, and has deployed radiopharmaceutical pipelines via introduction, self-development, and collaborative development.",
  science: {
    title:
      "Nuclear medicine forms the cornerstone of precision medicine, which achieves quantitative, accurate diagnoses and focus-targeted, precision treatments",
    li1: "By substituting radioisotopes, the same drug conjugates can be used for diagnostic imaging and therapeutic purposes, known as radioligand imaging (diagnostic nuclear medicines) and radioligand therapy (therapeutic nuclear medicines), respectively.",
    li2: "By directly observing the uptake of conjugated drugs in lesions via imaging, suitable patients can be selected for therapeutic radioisotope treatments (such as <sup style='transform: scale(0.7);display: inline-block'>177</sup>Lu, <sup  style='transform: scale(0.7);display: inline-block'>225</sup>Ac)",
  },
  pipeline: {
    title1:
      "1. Our focus on developing the most leading-edge and competitive global equity pipeline in nuclear medicine has set our FAPi products at the global forefront",
    li1: "The main application areas of the nuclear drug pipeline target a variety of solid tumors and other fields",
    li2: "Applying short-half-life nuclide <sup style='transform: scale(0.7);display: inline-block'>68</sup>Ga and long-half-life nuclide <sup style='transform: scale(0.7);display: inline-block'>177</sup>Lu to diagnosis and treatment, respectively, we have assembled a bountiful diagnostic and therapeutic product pipeline",
    table1: {
      thead: [
        "",
        "Candidate\nProduct",
        "Target",
        "Drug Discovery Preclinical R&D",
        "IND",
        "Phase I",
        "Phase II",
        "Phase III",
        "BLA/NDA",
      ],
      thyroid: "Thyroid\nCancer",
      tumors: "Solid\nTumors",
      prostate: "Prostate\nCancer",
      early: "10+ Early\nPrograms",
      undisclosed: "Undisclosed",
      note: "Note：Rights reserved jointly by China Isotope & Radiation Corp/HTA Co.Ltd.",
      core: "Core Products",
      diagnosis: "Diagnosis",
      treatment: "Treatment",
    },
    title2:
      "2. The AI platform encompasses a globally competitive product portfolio that aids drug research and development, while providing a secondary driver of growth for our business",
    li3: "The main application areas of the AI product line are in the cardiovascular, tumor, and neurological fields, and related areas",
    table2: {
      thead: [
        "",
        "Product\nName",
        "lmaging\nModality",
        "Anticipated Clinical Use",
        "Preclinical\nDevelopment",
        "Clinical\nExperiments",
        "Registration\nDeclaration",
        "NMPA/CE\nApproval",
        "FDA\nApproval",
      ],
      tr1T: "Cardiovascular",
      tr1P: "Deep MPI SPECT",
      tr1M: "Diagnosis of obstructive\ncoronary aitery disease",
      tr2T: "Tumor",
      tr2P: "Deep FDG PET",
      tr2M: "FDG imaging aided\ndiagnosis",
      tr3P: "Deep PSMA PET",
      tr3M: "PSMA imaging aided\ndiagnosis",
      tr4P: "Deep FAPI PET",
      tr4M: "FAPI imaging aided\ndiagnosis",
      tr5P: "Deep Dosimetry",
      tr5M: "Radiopharmaceutical\ndosimetrv evaluation",
      tr6P: "Deep PERCIST",
      tr6M: "Radiopharmaceutical\nefficacy evaluation",
      tr7P: "Deep Prostate",
      tr7M: "Auxiliary diagnosis of\n" + "prostate cancer",
      tr8P: "Deep Brain PET",
      tr8M: "Brain disease PET imaging\n" + "diagnosis",
      bottom1: "Core Products",
      bottom2: "NMPA\nII Evidence",
      bottom3: "NMPA\nIII Evidence",
    },
  },
};
const esg = {
  title: "OUR ESG STATEMENT",
  name: "Dr Wang Shiwei",
  introduce: "Founder& · Chairman · Chief Executive Officer",
  details:
    "Evomics’ ESG plan comprises an exhaustive array of major commitments across all the Environmental, Social,\n" +
    "and Governance (ESG) components that steer the attainment of our company’s strategy. It encompasses bold \n" +
    "targets to be realized via detailed action plans.",
  list: [
    {
      title: "Environmental Aspect",
      ul: [
        "We will achieve carbon neutrality and 75 percent of energy used in our operations will come from renewable sources by 2025. We will cut our CO2 emissions in line with a 1.5 °C global warming scenario (per science-based targets).",
        "Twenty-five percent of our revenue will derive from circular products and services, and we will accept trade-ins of all professional medical equipment, with responsible repurposing thereof by 2025.",
        "We will enshrine circular practices at our sites and generate no landfill waste by 2025.",
        "All new products will satisfy our environmental requirements by 2025, with ‘eco-paragons' making up 25 percent of revenues.",
        "We work with our vendors to lessen the environmental impact of our supply chain in line with a 1.5 °C global warming scenario (per science-based targets). We act in tandem with our partners and other firms to attain sustainable operations in accordance with the United Nations Sustainable Development Goals.",
      ],
    },
    {
      title: "Social",
      ul: [
        "We seek to better the health and wellbeing of 2 billion people by 2025, including 300 million in marginalized populations.",
        "Our strategy is to lead with innovations along the health continuum by helping our customers provide better health outcomes and experiences for patients and staff at lower cost, while assisting people in better tending to their own health.",
        "We seek to provide an ideal workplace by offering education and development opportunities, celebrating diversity and inclusion, and ensuring a safe, secure, and healthy work setting. We pay more than a living wage and encourage employee involvement beyond a mere high-performance standard.",
        "Our supplier development plan will enhance the life quality of 10,000 personnel in our supply chain by 2025.",
        "We reach out to and aid communities in which we operate, e.g., through volunteering, internships, and STEM (Science, Technology, Engineering, Mathematics) drives. We donate to non-profits that provide healthcare to the disadvantaged.",
        "Our tax payments contribute to the communities where we operate as a means of generating social value.",
      ],
    },
    {
      title: "Governance",
      ul: [
        "Our corporate governance combines responsible leadership with independent oversight.",
        "The Evomics Business System is our integral operating mode. It sets forth how we work together to please our clientele and achieve our firm’s targets by making good use of our worldwide reach and abilities.",
        "We are dedicated to supplying the best-quality products and services pursuant to applicable laws and standards.",
        "Our compensation scheme is devised to encourage employees to deliver on our purpose and create value, and to motivate and retain them. Our executive incentive plan features environmental and social commitments.",
        "We ensure ethical behavior through our General Business Principles, with a strict compliance and reporting mechanism.",
        "Our risk management provides rational guarantees that strategic and operational aims are fulfilled, legal requirements complied with, and the company’s reporting and relevant disclosures safeguarded.",
        "We are open about our plans, activities, results, and contributions to society (e.g., tax reporting), and connect with shareholders, customers, business partners, and authorities via various channels.",
      ],
    },
  ].map(({ title, ul }) => {
    return {
      title,
      ul,
      ulLength: ul.length + "",
    };
  }),
  listLength: () => esg.list.length + "",
};
const newsRoom = {
  title1: "LATEST",
  title2: "You can also find Evomics on the following media platforms",
};
export { nav, footer, home, whoWeAre, whatWeDo, esg, newsRoom };
