const nav = {
  home: "主页",
  whoWeAre: "公司简介",
  whatWeDo: "产品与服务",
  esg: "ESG",
  newsroom: "新闻资讯",
};

const footer = {
  touch: "留下您的联系方式",
  firstName: "名字",
  lastName: "姓氏",
  email: "电子邮箱",
  submit: "提交",
  section1: [
    "中国  · 上海",
    "奥地利 · 维也纳",
    "联系我们：mail{'@'}evomics.net",
    "人事咨询：hr_helpdesk{'@'}evomics.net",
  ],
  section1Length: () => footer.section1.length + "",
  section2T: "关于域唯医疗",
  section2: ["公司简介", "产品与服务", "ESG", "新闻资讯"],
  section3T: "关注我们",
  copyRight: "版权 © 2023 域唯医疗保留所有权利",
};

const home = {
  carouselList: [
    {
      title: "域唯医疗",
      desc: "一家领先的核医学公司",
    },
    {
      title: "域唯医疗",
      desc: "超越视界",
    },
    {
      title: "敬请期待",
      desc: "网站建设中…",
    },
  ],
  section1: {
    title: "公司简介",
    desc: "公司成立于 2018 年，我们是一家全球领先的核医学公司，致力于利用放射的力量，为全球患者提供创新型放射治疗。",
    p: "我们的业务遍及欧洲、中国和北美地区，致力于提供全面的综合诊断、放射治疗和人工智能解决方案，从而改进患者的治疗效果。",
  },
  section2: {
    title: "产品与服务",
    p1: "我们以临床转化为起点，成功打造以影像分析为核心的全流程平台，可实现核医学高效转化。",
    p2: "域唯医疗围绕我们的三大主要技术平台，打通放射性药物研发全过程，通过技术引进、自主研发和协同开发布局放射性药物管线。",
    p3: "域唯医疗的三大核心平台：",
    list: [
      "我们的医疗科技转化平台包括：机制探讨、药物靶点筛选和临床转化",
      "域唯医疗成像人工智能研发平台：临床数据标准化、数据分析协助以及临床计划的患者筛查",
      "域唯医疗核药物设计平台： 增加药物在肿瘤病变中的停留时间和肿瘤病变中的放射性同位素暴露",
    ],
    listLength: () => home.section2.list.length + "",
  },
  visions: [
    "我们的愿景",
    "超越视界",
    "放射是一把双刃剑。如果不正当或不谨慎使用，将危及生命。 但如果将其善加利用，则有助于发现原本难以察觉的疾病和损伤，救助身患绝症的患者。",
    "域唯医疗致力于履行自己的使命，将放射用作有益工具并结合强大的人工智能，为饱受病痛折磨的患者提供治疗，让他们重获健康。域唯医疗立足于放射与人工智能这两大潮流的交汇点，我们站在人类进步的前列，为追求全民健康的理想而奋斗。",
  ],
  visionsLength: () => home.visions.length + "",
  values: [
    "我们的价值观",
    "利用放射的力量，造福患者",
    "域唯医疗坚信，身体健康应该是一项公共利益，是全人类不可剥夺的权利。为贯彻这一精神，我们聚集了所有的精力和资源，并基于我们的成像和药物输送平台，设计出领先的创新型放射疗法和人工智能应用，为所有人提供可负担得起的优质医疗保健服务。",
  ],
  valuesLength: () => home.values.length + "",
};
const whoWeAre = {
  title: "域唯医疗",
  desc: "利用放射的力量，造福患者",
  subMenuList: ["公司概括", "领导团队", "科学咨询委员会", "投资者"],
  subMenuListLength: () => whoWeAre.subMenuList.length + "",
  ourCompany: {
    aboutUs: "关于我们",
    aboutUsT: "域唯医疗成立于 2018 年，是一家全球领先的核医学转化平台。",
    aboutUsP1:
      "公司与业内的领先合作伙伴携手，打造以核医学为核心、涵盖从靶分子筛选到上市后生产供应的全流程核医学体系。",
    aboutUsP2:
      "公司的三大核心平台有助于解决放射性药物研发的诸多痛点，从而有效缩短药物研发周期，并提高研发成功率。",
  },
  visions: {
    vision: "我们的愿景",
    visionDesc: "超越视界",
    visionP1:
      "放射是一把双刃剑。如果不正当或不谨慎使用，将危及生命。但如果将其善加利用，则有助于发现原本难以察觉的疾病和损伤，救助身患绝症的患者。",
    visionP2:
      "域唯医疗致力于履行自己的使命，将放射用作有益工具并结合强大的人工智能，为饱受病痛折磨的患者提供治疗，让他们重获健康。域唯医疗立足于放射与人工智能这两大潮流的交汇点，我们站在人类进步的前列，为追求全民健康的理想而奋斗。",
  },
  values: {
    values: "我们的价值观",
    valuesDesc: "利用放射的力量，造福患者",
    valuesP:
      "域唯医疗坚信，身体健康应该是一项公共利益，是全人类不可剥夺的权利。\n" +
      "为贯彻这一精神，我们聚集了所有的精力和资源，并基于我们的成像和药物输送平台，设计出领先的创新型放射和人工智能应用，为所有人提供可负担得起的优质医疗保健。",
  },
  teams: [
    {
      name: "王世威博士",
      keywords: ["创始人", "董事长", "首席执行官"],
      descList: [
        "曾在中国和美国成功孵化/投资/共同创办新旭医药、再极医药、璎黎药业、安立玺荣等创新型制药企业，并担任董事、高管\n",
        "其所孵化的企业涵盖放射性药物、靶向小分子药物、单克隆抗体药物、ADC 药物、多肽药物、细胞治疗及其他领域\n",
        "王世威博士持有北京大学生物化学与分子生物学博士",
      ],
    },
    {
      name: "Marcus Hacker 博士",
      keywords: ["联合创始人", "首席医疗官"],
      descList: [
        "全球最具影响力的核医学专家之一",
        "全球核医学研究领域的领军人物",
        "奥地利核医学学会主席、欧洲核医学会心血管分会主席、德国核医学学会副主席、国际原子能机构特聘专家",
        "维也纳综合医院医学影像科主任、维也纳医科大学核医学教授",
      ],
    },
    {
      name: "王世峰，MBA",
      keywords: ["联合创始人", "董事", "首席运营官"],
      descList: [
        "在共同创办域唯医疗之前，他曾在《第一财经日报》的产业经济中心担任高级记者，专注于高端制造和技术方面的报道。",
        "曾创办全球领先的创新型医疗科技社区平台 The Yuan，并担任总编辑",
        "曾任跨国科技公司（罗克韦尔自动化和 ABB）的媒体公关顾问",
      ],
    },
    {
      name: "李翔博士",
      keywords: ["联合创始人", " 首席科学官"],
      descList: [
        "欧洲最有影响力的核医学学者之一。研究成果曾发表在《Circulation》、《JACC》、《JNM》、《EJNMMI》等顶级期刊上",
        "多次获得美国核医学学会(SNMMI)青年研究者奖",
        "奥地利维也纳综合医院/维也纳医科大学核医学教授，北京安贞医院核医学教授，山西医科大学兼职教授",
        "",
      ],
    },
  ].map(({ name, keywords, descList }) => {
    return {
      name,
      keywords,
      keywordsLength: keywords.length + "",
      descList,
      descListLength: descList.length + "",
    };
  }),
};
const whatWeDo = {
  title: "专注于核医学",
  desc: "域唯医疗致力于开发靶向放射性药物和 AI 成像产品，打造一体化核医学诊断和治疗闭环",
  subMenu: ["技术平台", "科学理念", "产品管线"],
  subMenuListLength: () => whatWeDo.subMenu.length + "",
  approach:
    "域唯医疗围绕其核医学技术转化平台、AI 成像平台、核医学设计平台，打通药物研发全过程，通过技术引进、自主研发和协同开发布局放射性药物管线。",
  science: {
    title:
      "核医学是精准医学的基石，精准医学旨在实现定量、精准诊断和重点靶向精准治疗。",
    li1: "在同一药物偶联物中代入放射性同位素，即可实现诊断成像和治疗目的，二者分别称为放射性配体成像（诊断核医学）和放射性配体治疗（治疗核医学）。",
    li2: "通过成像技术直接观察药物偶联物在病变中的摄吸收情况，即可选择合适的患者进行放射性同位素治疗（如<sup style='transform: scale(0.7);display: inline-block'>177</sup>Lu、<sup  style='transform: scale(0.7);display: inline-block'>225</sup>Ac）。",
  },
  pipeline: {
    title1:
      "1. 我们专注于在核医学领域开发领先和具有竞争力的全球产品管线，推动我们的 FAPi 产品走在全球前列。",
    li1: "核药物管线的主要应用领域为多种实体瘤及其他领域。",
    li2: "通过将短半衰期核素<sup style='transform: scale(0.7);display: inline-block'>68</sup>Ga 和长半衰期核素<sup style='transform: scale(0.7);display: inline-block'>177</sup>Lu 分别应用于诊断和治疗，我们构建了丰富的诊断和治疗产品管线。",
    table1: {
      thead: [
        "",
        "候选产品",
        "靶点",
        "临床前研发",
        "新药研究\n申请 (IND)",
        "临床I 期",
        "临床II 期",
        "临床III 期",
        "生物制品许可\n申请/新药申请\n(BLA/NDA)",
      ],
      thyroid: "甲状腺癌",
      tumors: "实体瘤",
      prostate: "前列腺癌",
      early: "10+ 个\n早期项目",
      undisclosed: "未披露",
      note: "注：中国同辐股份有限公司/HTA Co.Ltd. 共同保留相应权利。",
      core: "核心产品",
      diagnosis: "诊断",
      treatment: "治疗",
    },
    title2:
      "2. AI 平台包含具有全球竞争力的产品组合，不仅有助于药物研发，还能为我们的业务增长提供第二动力。",
    li3: "AI 产品线的主要应用领域为心血管、肿瘤、神经及相关领域。",
    table2: {
      thead: [
        "",
        "产品名称",
        "成像模式",
        "预期临床用途",
        "临床前开发",
        "临床试验",
        "登记申报",
        "NMPA/CE\n批准",
        "FDA\n批准",
      ],
      tr1T: "心血管",
      tr1P: "Deep MPI SPECT",
      tr1M: "阻塞性冠状动脉疾病的诊断",
      tr2T: "肿瘤",
      tr2P: "Deep FDG PET",
      tr2M: "FDG 成像辅助诊断",
      tr3P: "Deep PSMA PET",
      tr3M: "PSMA 成像辅助诊断",
      tr4P: "Deep FAPI PET",
      tr4M: "FAPI 成像辅助诊断",
      tr5P: "Deep Dosimetry",
      tr5M: "放射性药物剂量测定评估",
      tr6P: "Deep PERCIST",
      tr6M: "放射性药物剂量疗效评估",
      tr7P: "Deep Prostate",
      tr7M: "前列腺癌的辅助诊断",
      tr8P: "Deep Brain PET",
      tr8M: "脑部疾病 PET 成像诊断",
      bottom1: "核心产品",
      bottom2: "NMPA \n II 类证据",
      bottom3: "NMPA \n III 类证据",
    },
  },
};
const esg = {
  title: "ESG 声明",
  name: "王世威博士",
  introduce: "创始人 · 董事长 · 首席执行官",
  details:
    "域唯医疗的 ESG 计划涵盖一系列有关环境（Environmental）、社会（Social）和治理（Governance）\n方方面面的详尽承诺，指引着我们妥善落实公司战略，并通过详细的行动计划实现清晰的目标。",
  list: [
    {
      title: "环境方面",
      ul: [
        "我们将在 2025 年之前实现碳中和，并采用可再生能源满足 75% 的运营需求。我们将减少二氧化碳排放，助力实现全球1.5 °C 温控目标（科学设定目标）。",
        "到 2025 年，我们 25% 的收入将来自循环再生型产品和服务，所有专业医疗设备将能以旧换新，并且我们会负责任地再利用旧设备。",
        "我们将在工厂推行循环再生实践，并在 2025 之前实现填埋废物零产出。",
        "截至 2025 年，所有新产品均将满足我们的环保要求，“环境友好模范型”业务将达到收入的 25%。",
        "我们携手我们的供应商，降低供应链对环境的影响，助力实现全球 1.5 °C 温控目标（科学设定目标）。我们与合作伙伴和其他公司协作，依据联合国可持续发展目标实现可持续运营。",
      ],
    },
    {
      title: "社会方面",
      ul: [
        "我们致力于在 2025 年之前改善 20 亿人口的健康和福祉，包括 3 亿边缘化群体。 ",
        "我们致力于采取创新战略，通过帮助客户以更低的成本为患者和员工提供更好的健康结果和体验，同时帮助人们更好地关爱自己的健康，改善人们的健康续谱。 ",
        "我们通过提供教育和发展机会，拥抱多样性和包容性，以及保障安全、安心和健康的工作环境，力求打造一个理想的工作场所。 我们提供优厚的薪资待遇，鼓励提升员工参与度，而非仅限于达到高绩效标准。 ",
        "截至 2025 年，我们的供应商开发计划将提升供应链中 10,000 名人员的生活质量。 ",
        "通过志愿服务、实习和 STEM（科学、技术、工程、数学）等活动，我们与自身所立足的社区开展广泛接触，并为他们提供帮助。 我们会向为弱势群体提供医疗保健的非营利组织进行捐款。 ",
        "我们积极纳税，以这种方式创造社会价值，为我们所立足的社区做出贡献。",
      ],
    },
    {
      title: "治理方面",
      ul: [
        "我们以领导担责和独立监督相结合的方式进行企业治理。 ",
        "我们通过域唯医疗商业系统 — 这一整合运营模式，充分利用我们的全球影响力和能力，齐心协力满足客户需求，实现公司目标。",
        "我们致力于依据适用法律和标准提供优质的产品和服务。 ",
        "我们的薪酬计划旨在鼓励员工实现我们的目标，创造价值，并激励和留住人才。我们的高管激励计划包含环境和社会承诺。 ",
        "我们通过我们的一般商业原则以及严格的合规和报告机制来确保道德行为。 ",
        "我们的风险管理为实现战略和运营目标、遵守法律要求以及保障公司的报告和相关披露提供了合理的保证。 ",
        "我们会公开我们的计划、活动、业绩和对社会的贡献（如税务报告），并通过各种渠道与股东、客户、业务合作伙伴和当局保持联系。",
      ],
    },
  ].map(({ title, ul }) => {
    return {
      title,
      ul,
      ulLength: ul.length + "",
    };
  }),
  listLength: () => esg.list.length + "",
};
const newsRoom = {
  title1: "最新资讯",
  title2: "您还可在以下媒体平台找到关于域唯医疗的信息",
};
export { nav, footer, home, whoWeAre, whatWeDo, esg, newsRoom };
